<div class="toolbar-user-container" *ngIf="user">
  <button mat-button
          [matMenuTriggerFor]="content"
          class="toolbar-user-btn minWidth70-xs">
    <div class="fxLayoutRow fxLayoutAlignStart fxLayoutAlignCrossAxisCenter">
      <span class="fxLayoutColumn"><img class="avatar" alt="avatar" src="./assets/user/profile.jpg"/></span>
      <div class="name fxHide fxShow-gt-xs">
        <p>{{ user.firstName }} {{ user.lastName }}</p>
        <p><cite>{{(description | uppercase) || ''}}</cite></p>
      </div>
      <mat-icon class="icon fxHide fxShow-gt-xs">keyboard_arrow_down</mat-icon>
    </div>
  </button>
  <div class="user-menu mat-elevation-z1" [class.open]="isMenuOpen">
    <mat-menu class="content" #content="matMenu" style="width: 13rem">
      <button mat-menu-item (click)="goToProfile.emit()">
        <mat-icon>account_circle</mat-icon>
        <a matLine>My Profile</a>
      </button>
      <button mat-menu-item (click)="goToSupport.emit()">
        <mat-icon>headset_mic</mat-icon>
        <a matLine>Support</a>
      </button>
      <button mat-menu-item matLine [matMenuTriggerFor]="tenants">
        <mat-icon>language</mat-icon>
        <a>Tenant List</a>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="switchTheme.emit()">
        <mat-icon>invert_colors</mat-icon>
        <a  matLine>Change theme</a>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="doLogout.emit()">
        <mat-icon>exit_to_app</mat-icon>
        <a matLine >Logout</a>
      </button>
    </mat-menu>
    <mat-menu #tenants="matMenu">
      <button mat-menu-item *ngFor="let tenant of availableTenants"
              (click)="tenantChange.emit(tenant)">{{tenant.name}}</button>
    </mat-menu>
  </div>
</div>
