import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {USER_MODULES} from './config/modules.config';
import {AppState} from './store/app.state';
import {Store} from '@ngrx/store';
import {selectAllTenants, selectLoggedUser, selectTenantInUse, selectUserFeatures} from './store/auth/auth.selectors';
import {catchError, delay, filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {Observable, of, Subject} from 'rxjs';
import {SidenavItem} from './shared/components/sidenav/sidenav.component';
import {VERSION} from '../environments/version';
import {VersionInfo} from './models/version-info.model';
import {environment} from '../environments/environment';
import {User} from './models/user';
import {Theme} from './models/layout-state.model';
import {selectTheme} from './store/layout/layout.selectors';
import {FetchUserFeatures, UpdateIsBusinessUser} from './store/auth/auth.actions';
import * as LayoutActions from './store/layout/layout.actions';
import {Tenant} from './models/tenant.model';
import {LoadingService} from './services/loading.service';
import {BusinessApiService} from './services/api/business.api.service';
import {TenantsService} from './store/tenants/tenants.service';
import {Router} from '@angular/router';
import {AuthService} from './services/auth.service';

const switchTheme = (theme: Theme): void => {
  const body = document.getElementsByTagName('body')[0];
  if (theme === Theme.LightTheme) {
    body.classList.remove(Theme.DarkTheme);
  } else {
    body.classList.remove(Theme.LightTheme);
  }
  body.classList.add(theme);
};

@Component({
  selector: 'rina-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss', 'flexLayout.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  public versionInfo: VersionInfo = VERSION;
  public companyName: string = environment.companyName;
  public companyLogo: string = environment.companyLogo;

  public modules$: Observable<SidenavItem[]>;
  public loggedUser$: Observable<User | null>;
  public theme$: Observable<Theme>;
  public tenantInUse$: Observable<Tenant | null>;
  public loading = false;
  public isBusinessUser$: Observable<boolean>;
  tenants$: Observable<Tenant[]>;
  private _destroy$: Subject<void> = new Subject();

  constructor(private store: Store<AppState>,
              private _tenantsService: TenantsService,
              private _auth: AuthService,
              private router: Router,
              private loadingService: LoadingService,
              private cd: ChangeDetectorRef,
              private businessApi: BusinessApiService) {
    const urlParams = new URLSearchParams(window.location.search);
    const paramActiveTenant = urlParams.get('tenants');
    if (paramActiveTenant) {
      this._tenantsService.setActive({name: paramActiveTenant});
    }
    this.loggedUser$ = this.store.select(selectLoggedUser());

    this.tenants$ = this.store.select(selectAllTenants());
    this.isBusinessUser$ = this.tenants$.pipe(
      take(1),
      switchMap(tenants => {
        if (tenants.some(tenant => tenant.name === environment.companyTenant.name)) {
          return of(true)
        }
        return of(false);
      }),
      tap(isBusiness => this.store.dispatch(UpdateIsBusinessUser({isBusiness}))));
    this.modules$ = this.store.select(selectUserFeatures())
      .pipe(switchMap(() => of(USER_MODULES)));
    this.theme$ = this.store.select(selectTheme());
    this.tenantInUse$ = this.store.select(selectTenantInUse());
  }

  ngOnInit(): void {
    this.theme$.pipe(takeUntil(this._destroy$)).subscribe(theme => switchTheme(theme));
    this.tenantInUse$
      .pipe(
        filter(tenant => !!tenant),
        switchMap(() => this.loggedUser$),
        take(1))
      .subscribe(user => {
        if (user) {
          this.store.dispatch(FetchUserFeatures({user}));
        }
      });
    this.listenToLoading();
  }

  listenToLoading(): void {
    this.loadingService.loading$
      .pipe(delay(0),
        tap(_ => this.cd.markForCheck())) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe(loading => this.loading = loading);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public doLogout(): void {
    this._auth.logout();
  }

  public goToProfile(): void {
    window.open(environment.profileUrl, '_blank');
  }

  public goToSupport(): void {
    window.open(environment.supportUrl, '_blank');
  }

  public switchTheme(): void {
    this.store.dispatch(LayoutActions.SwitchTheme());
  }

  onTenantChange(event: Tenant): void {
    this._tenantsService.setActive(event);
    setTimeout(() => {
      if (this.router.url.includes('edit')) {
        // the url will be formated as eg. /edit/Roles/supervisor taking only [2] will bring back to the table of roles
        this.router.navigate([this.router.url.split('/')[2]]);
      } else {
        // refresh this page note that sameUrlNavigation is set to 'reload' and resolver are set to run everytime
        this.router.navigateByUrl(this.router.url);
      }
    }, 600);
  }
}
